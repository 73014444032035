.fogot-pass-txt{
 text-align: center;
 font-family: "Inter", sans-serif;
font-size: 34px;
font-weight: 400;
}
.fogot-container{
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.email-txt-field{
    border: none !important;
}


.responsive-input {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    font-size: 1.8rem;
    text-align: center;
    margin: 0 8px;
    outline: none;
    background-color: #FFF;
    color: #000;
    box-shadow: 0 0 45px rgba(0, 0, 0, 0.06);
    border: none; 
}

.responsive-input.focused {
    border: 1px solid #EA1D26; 
}

@media (max-width: 480px) {
    .responsive-input {
        padding: 0px !important;
        width: 60px !important;
        height: 50px !important;
        font-size: 16px !important; 
    }
    .css-zdkuze {
      
        padding: 20px !important;
    }
   
   
   
}

@media screen and (max-width:380px) {
    .css-zdkuze {
      
        padding: 0px !important;
    }
    .responsive-input {
        padding: 0px !important;
        width: 42px !important;
        height: 42px !important;
        font-size: 16px !important; 
    }
}