/* General container for the tickets */
.ticket-container {
    max-width: 1600px;
    border: 1px solid #ddd;  
    padding: 20px;
    margin: 20px 0;
    font-family: 'Inter', sans-serif;
    background-color: #fff; 
    border-radius: 8px;  
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    display: grid;  
    grid-template-columns: 1fr;  
    grid-gap: 20px; 
    border-radius: 25px;
}

/* Ticket Title */
.ticket-title,
.ticket-desc {
    font-size: 16px;
    color: #333;  
    line-height: 1.6;  
    word-break: break-all;
}

.ticket-title strong,
.ticket-desc strong {
    color: #444;  
    font-weight: 600; 
}

/* Container for Status, Raised By, and Created At to align them in one row */
.ticket-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px; 
}

/* Styling individual footer items */
.ticket-status,
.ticket-raiseby-user,
.ticket-created {
    font-size: 14px;
    color: #333;
}

.ticket-status strong,
.ticket-raiseby-user strong,
.ticket-created strong {
    color: #444;
    font-weight: 600;
}


.ticket-status,
.ticket-raiseby-user,
.ticket-created {
    margin-right: 20px;
}

/* Responsive design for small screens */
@media (max-width: 768px) {
    .ticket-container {
        grid-template-columns: 1fr;  
    }
    .ticket-footer {
        flex-direction: column; 
    }
    .ticket-status,
    .ticket-raiseby-user,
    .ticket-created {
        margin-right: 0; 
    }
}
