
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(188, 187, 187, 0.7);
    z-index: 9999;
  }
  
  /* .loader {
    border: 12px solid #e9e9e9;
    border-radius: 50%;
    border-top: 12px solid #1227ff;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 1s linear infinite;
  } */
  
  .loader {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 12px solid transparent; /* Transparent border for the base */
    animation: spin 1s linear infinite;
  }
  
  .loader::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    background: conic-gradient(#4449FE, #13A6F2, #4449FE); /* Gradient */
    mask: radial-gradient(closest-side, transparent 60%, black 100%);
    -webkit-mask: radial-gradient(closest-side, transparent 60%, black 100%);
  }
  
  @-webkit-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  